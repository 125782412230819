import React, { useEffect, useState } from 'react';
import { Card, Col, FormGroup, Row } from 'reactstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { toast } from 'react-toastify';
import {
  CircularProgress,
  createMuiTheme,
  FormLabel,
  InputLabel,
  MuiThemeProvider,
  TextField,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RadioGroup from '../../Components/RadioGroup';
import SelectList from '../../Components/SelectList';
import { updateCampaign, updateCampaignUrl } from '../../Api/campaignApi';
import ja from 'date-fns/locale/ja';
import moment from 'moment';
import _ from 'lodash';
import './style.css';
import configs from '../../Config/config';
import { uploadImage } from '../../Api/uploadApi';
import { deleteImageStorage } from '../../Api/imageApi';
import Util from '../../Util/Util';
import {
  TEXT_HTML_MANAGEMENT_CONTENTS_CAMPAIGN_EDIT_LINK_MODAL,
  TEXT_HTML_MANAGEMENT_UPLOAD_FILE_TO_WEBVIEW_LINK_MODAL,
} from '../../Common/modal_text_info';
import {
  fileDataType,
  hiddenStatusRequired,
  inspectionStatusRequired,
  sexOptionsRequired,
  typeConnectionsRequired,
} from '../../Util/utils';
import { APP_ALLOW_UPLOAD_TYPE, DIR_FOLDER_IMAGE } from '../../Common/constant';
import FileUploadModal from '../../Components/FileUploadModal';
import { AppDestinationOptions, placeOptions, TOAST_MESSAGE } from '../../Common/constant_text';
import RemoveCampaignModal from './RemoveCampaignModal';
import { DeleteImageButton } from '../../Components/IconImageAction';
import { updatePopupUrl } from '../../Api/popupApi';

registerLocale('ja', ja);
const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      h6: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      },
    },
  },
});

function EditCampaignModal({ campaign, onClose, onUpdate }) {
  const [submitting, setSubmitting] = useState(false);

  const [edittingCampaign, setCampaign] = useState(null);
  const [hourStart, setHourStart] = useState('00');
  const [minusStart, setMinusStart] = useState('00');
  const [hourEnd, setHourEnd] = useState('00');
  const [minusEnd, setMinusEnd] = useState('00');
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [showDialogDragDrop, setShowDialogDragDrop] = useState(false);
  const [fileData, setFileData] = useState(fileDataType);
  const [confirmVisible, setConfirmVisible] = useState(false);

  useEffect(() => {
    const data = _.clone(campaign);
    setHourStart(moment(new Date(parseInt(data.start_time))).format('HH'));
    setMinusStart(moment(new Date(parseInt(data.start_time))).format('mm'));
    setHourEnd(moment(new Date(parseInt(data.end_time))).format('HH'));
    setMinusEnd(moment(new Date(parseInt(data.end_time))).format('mm'));
    setDateStart(moment(new Date(parseInt(data.start_time))).format('YYYY-MM-DD'));
    setDateEnd(moment(new Date(parseInt(data.end_time))).format('YYYY-MM-DD'));
    setCampaign(data);
  }, [campaign]);

  useEffect(() => {
    if (fileData && fileData.file) {
      uploadStartLogic(fileData.file); // Trigger call data
    }
  }, [fileData]);

  const onDeleteImage = (url) => {
    if (url) {
      deleteImageStorage(Util.getFilePath(url)).then();
    }
  };

  const handleRemoveConfirm = () => {
    setConfirmVisible(true);
  };

  const handleRemoveClose = () => {
    setConfirmVisible(false);
  };

  const onChange = (e) => {
    edittingCampaign[e.target.name] = e.target.value;
    setCampaign({ ...edittingCampaign, [e.target.name]: e.target.value });
  };

  const handleDate = (date, name) => {
    date = moment(date).format('YYYY-MM-DD');
    if (name === 'start_time') {
      setDateStart(date);
      let startTime = new Date(`${date} ${hourStart}:${minusStart}:00`);
      edittingCampaign.start_time = startTime.getTime();
    } else {
      setDateEnd(date);
      let endTime = new Date(`${date} ${hourEnd}:${minusEnd}:00`);
      edittingCampaign.end_time = endTime.getTime();
    }

    setCampaign({ ...edittingCampaign });
  };

  const handleHour = (e, name) => {
    let value = e.target.value;
    if (name === 'start_hour') {
      setHourStart(value);
      let startTime = new Date(`${dateStart} ${value}:${minusStart}:00`);
      edittingCampaign.start_time = startTime.getTime();
    } else {
      setHourEnd(value);
      let endTime = new Date(`${dateEnd} ${value}:${minusEnd}:00`);
      edittingCampaign.end_time = endTime.getTime();
    }
    setCampaign({ ...edittingCampaign });
  };

  const handleMinus = (e, name) => {
    let value = e.target.value;
    if (name === 'start_minus') {
      setMinusStart(value);
      let startTime = new Date(`${dateStart} ${hourStart}:${value}:00`);
      edittingCampaign.start_time = startTime.getTime();
    } else {
      setMinusEnd(value);
      let endTime = new Date(`${dateEnd} ${hourEnd}:${value}:00`);
      edittingCampaign.end_time = endTime.getTime();
    }
    setCampaign({ ...edittingCampaign });
  };

  const handleSubmit = () => {
    if (!edittingCampaign.name) {
      toast.error('設定名を入力してください。');
      return false;
    }
    if (!edittingCampaign.place) {
      toast.error('表示場所を選択してください。');
      return false;
    }
    if (!edittingCampaign.start_time) {
      toast.error('開始日を入力してください');
      return false;
    }
    if (!edittingCampaign.app_destination) {
      toast.error('リンク先ページを指定してください');
      return false;
    }
    if (!edittingCampaign.end_time) {
      toast.error('終了日を入力してください');
      return false;
    }
    if (!edittingCampaign.url) {
      toast.error('バナー画像を登録してください。');
      return false;
    }
    if (edittingCampaign.start_time > edittingCampaign.end_time) {
      toast.error('開始日は終了日より前で設定してください');
      return false;
    }
    const data = _.pick(edittingCampaign, [
      'id',
      'name',
      'url',
      'place',
      'typeDevice',
      'displaySetting',
      'rank',
      'gender',
      'start_time',
      'end_time',
      'confirmAge',
      'app_destination',
    ]);

    updateCampaign({ ...data, updatedDate: new Date().getTime() }).then(
      (response) => {
        const { data } = response;
        if (data.success) {
          onClose();
          onUpdate();
          setSubmitting(true);
          toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
        } else {
          toast.error(TOAST_MESSAGE.ERROR_EDIT);
        }
      },
    );
  };

  let hour = [];
  for (let index = 0; index < 24; index++) {
    if (index < 10) {
      hour.push('0' + index);
    } else {
      hour.push(index);
    }
  }

  if (!edittingCampaign) return null;

  const uploadStartLogic = async (file, task) => {
    if (configs.allowUpload === APP_ALLOW_UPLOAD_TYPE.SERVER) {
      if (task) {
        task.cancel();
      }

      const filename = new Date().getTime() + '.jpg';
      const formData = new FormData();
      formData.append('imageFile', new File([file], filename));
      const dir = `${DIR_FOLDER_IMAGE.CAMPAIGN}/${moment().format('YYYYMMDD')}`;
      const imagePath = `${configs.apiDomain}/${dir}/${filename}`;
      const { data: uploaded } = await uploadImage(formData, { dir: btoa(dir) });
      if (uploaded.data) {
        onDeleteImage(campaign.url);
        // Update image after change
        updateCampaignUrl(campaign.id, { url: imagePath }).then(() => onUpdate());
        edittingCampaign.url = imagePath;
        setCampaign({ ...edittingCampaign });
      }
    }
  };

  const handleDeleteImage = (image) => {
    updateCampaignUrl( campaign.id, { url: image, isDeleted: true }).then(() => {
      edittingCampaign.url = null;
      setCampaign({ ...edittingCampaign });
      onDeleteImage(image);
      onUpdate();
    });
  };

  const handleUploading = (snapshot) => {
    // Todo
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.info('Upload is ' + progress + '% done');
  };

  // const handleUploadError = (payload) => {
  //   console.error('Firebase.storage::FAIL ', payload);
  // };

  const handleUploadSuccess = (url) => {
    if (String(url)) {
      edittingCampaign.url = url;
      setCampaign({ ...edittingCampaign });
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={true}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <MuiThemeProvider theme={theme}>
          <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
            <p className={'d-title'}>キャンペーン設定－編集</p>
            <div>
              <center>
                <Button
                  onClick={onClose}
                  color="primary"
                  disabled={submitting}
                  className="btn-custom btn-red"
                >
                  閉じる
                </Button>
                <Button
                  onClick={() => handleSubmit()}
                  color="primary"
                  disabled={submitting}
                  className="btn-custom btn-blue mr-3"
                >
                  {submitting && <CircularProgress />}
                  登録
                </Button>
              </center>
            </div>
          </DialogTitle>
          <div className={'d-title-info-box'}>
            <div className={'text mb-0'}
                 dangerouslySetInnerHTML={{ __html: TEXT_HTML_MANAGEMENT_CONTENTS_CAMPAIGN_EDIT_LINK_MODAL }}></div>
          </div>
        </MuiThemeProvider>
        <DialogContent>
          <Row>
            <Col xs={6}>
              <Card body>
                <FormGroup row>
                  <Col xs={12}>
                    <InputLabel shrink>
                      <span className="text-red">*</span>設定名
                    </InputLabel>
                    <TextField
                      type="text"
                      defaultValue={edittingCampaign.name}
                      name="name"
                      onChange={onChange}
                      className="w-100"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs={12}>
                    <InputLabel shrink><span className="text-red">*</span>表示場所</InputLabel>
                    <SelectList
                      name="place"
                      defaultValue={edittingCampaign.place}
                      options={placeOptions}
                      onChange={onChange}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs={12}>
                    <InputLabel shrink>表示端末</InputLabel>
                    <RadioGroup
                      name="typeDevice"
                      defaultValue={edittingCampaign.typeDevice}
                      options={typeConnectionsRequired()}
                      onChange={onChange}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs={12}>
                    <FormLabel component="legend">×××性別</FormLabel>
                    <RadioGroup
                      name="gender"
                      defaultValue={!!edittingCampaign?.gender ? edittingCampaign.gender : ''}
                      options={sexOptionsRequired()}
                      onChange={onChange}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs={12}>
                    <FormLabel component="legend">×××年齢認証</FormLabel>
                    <RadioGroup
                      name="confirmAge"
                      defaultValue={edittingCampaign.confirmAge}
                      options={inspectionStatusRequired()}
                      onChange={onChange}
                    />
                  </Col>
                </FormGroup>
              </Card>
            </Col>
            <Col xs={6}>
              <Card body className="mb-2">
                <FormGroup row>
                  <Col xs={12}>
                    <FormLabel component="legend">開始日時</FormLabel>
                  </Col>
                  <Col className="datePicker" xs={12}>
                    <DatePicker
                      className="date"
                      selected={new Date(parseInt(edittingCampaign.start_time))}
                      onChange={(e) => handleDate(e, 'start_time')}
                      locale="ja"
                      dateFormat="yyyy/MM/dd"
                    />
                    <select
                      defaultValue={hourStart.toString()}
                      onChange={(e) => handleHour(e, 'start_hour')}
                      className="hour"
                    >
                      {hour.map((value) => (
                        <option key={value} value={value.toString()}>
                          {value}
                        </option>
                      ))}
                    </select>
                    <select
                      defaultValue={minusStart.toString()}
                      onChange={(e) => handleMinus(e, 'start_minus')}
                      className="minus"
                    >
                      <option value="00">00</option>
                      <option value="30">30</option>
                    </select>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col>
                    <FormLabel component="legend">終了日時</FormLabel>
                  </Col>
                  <Col xs={12} className="datePicker">
                    <DatePicker
                      className="date"
                      selected={new Date(parseInt(edittingCampaign.end_time))}
                      onChange={(e) => handleDate(e, 'end_time')}
                      locale="ja"
                      dateFormat="yyyy/MM/dd"
                    />
                    <select
                      defaultValue={hourEnd.toString()}
                      onChange={(e) => handleHour(e, 'end_hour')}
                      className="hour"
                    >
                      {hour.map((value) => (
                        <option key={value} value={value.toString()}>
                          {value}
                        </option>
                      ))}
                    </select>
                    <select
                      defaultValue={minusEnd.toString()}
                      onChange={(e) => handleMinus(e, 'minus_end')}
                      className="minus"
                    >
                      <option value="00">00</option>
                      <option value="30">30</option>
                    </select>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs={12}>
                    <FormLabel component="legend">表示設定</FormLabel>
                    <RadioGroup
                      name="displaySetting"
                      defaultValue={edittingCampaign.displaySetting}
                      options={hiddenStatusRequired()}
                      onChange={onChange}
                    />
                  </Col>
                </FormGroup>
              </Card>
              <Card body>
                <FormGroup row>
                  <Col xs={12}>
                    <InputLabel shrink>
                      <span className="text-red">*</span>リンク先
                    </InputLabel>
                    <SelectList
                      name="app_destination"
                      defaultValue={edittingCampaign.app_destination}
                      options={AppDestinationOptions}
                      onChange={onChange}
                    />
                  </Col>
                </FormGroup>

                {!edittingCampaign.url && (
                  <FormGroup row>
                  <Col xs={12}>
                    <FormLabel component="legend">
                      <span className="text-red">*</span>リンク画像を選択
                    </FormLabel>
                  </Col>
                  <Col xs={2}>
                    <label>
                      <AddBoxIcon onClick={() => setShowDialogDragDrop(true)}
                                  color="action"
                                  style={{ width: 50, height: 50, objectFit: 'cover', margin: 'auto' }}
                      />
                    </label>
                  </Col>
                </FormGroup>
                )}
              </Card>
            </Col>
          </Row>
          {edittingCampaign.url && (
            <Card body className="my-3 align-items-center">
              <label
                style={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  width: 220,
                }}
              >
                <img
                  style={{
                    width: '100%',
                    objectFit: 'contain',
                  }}
                  src={edittingCampaign.url}
                  alt="edittingCampaign"
                />
                {edittingCampaign.url && (
                  <DeleteImageButton onClick={() => handleDeleteImage(edittingCampaign.url)} />
                )}
              </label>
            </Card>
          )}

          <Button
            variant="contained"
            className="btn-custom btn-red float-right"
            onClick={handleRemoveConfirm}
            style={{ marginBottom: 2 }}
          >
            削除
          </Button>
        </DialogContent>
      </Dialog>

      {showDialogDragDrop && (
        <FileUploadModal showDialogDragDrop={showDialogDragDrop}
                         setShowDialogDragDrop={setShowDialogDragDrop}
                         setFileData={setFileData}
                         fileData={fileData}
                         dataInfoHeadText={TEXT_HTML_MANAGEMENT_UPLOAD_FILE_TO_WEBVIEW_LINK_MODAL}
        />
      )}

      {confirmVisible && (
        <RemoveCampaignModal
          visible={confirmVisible}
          onClose={handleRemoveClose}
          onCloseRoot={onClose}
          onUpdate={onUpdate}
          editingCampaign={edittingCampaign}
          onDeleteImage={onDeleteImage}
        />
      )}
    </>
  );
}

export default EditCampaignModal;
