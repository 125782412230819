import React, { useMemo } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import NoPhoto from '../../../assets/image/no_user_photo.png';
import Util from '../../../Util/Util';
import moment from 'moment';
import _ from 'lodash';
import { Link } from '@material-ui/core';

const LogSendListItem = ({ logSendSms, no, handleClickDetail }) => {
  const content = _.truncate(_.get(logSendSms, 'content'), {
    length: 24,
    separator: ' ',
  });
  return useMemo(
    () => (
      <TableRow style={{ height: 48 }}>
        <TableCell>{no}</TableCell>
        <TableCell>{_.get(logSendSms, 'phoneNumber')}</TableCell>
        <TableCell align="center">
          <img
            className="imgRound"
            src={_.get(logSendSms, 'user.avatar.url') || NoPhoto}
            alt=""
          />
        </TableCell>
        <TableCell>{_.get(logSendSms, 'user.nickName')}</TableCell>
        <TableCell>
          {Util.genderIdToText(_.get(logSendSms, 'user.gender'))}
        </TableCell>
        <TableCell>{content}</TableCell>
        <TableCell className="text-muted">
          {moment(Number(_.get(logSendSms, 'createdDate'))).format('YYYY-MM-DD HH:mm:ss')}
        </TableCell>
        <TableCell  className="d-color-link" style={{ cursor: 'pointer' }}>
          <Link onClick={() => handleClickDetail(logSendSms)} className="d-color-link">表示</Link>
        </TableCell>
      </TableRow>
    ),
    [logSendSms, no, content, handleClickDetail],
  );
};

export default LogSendListItem;
