import React, { useEffect, useState } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import LogSendNotifyComponent from './LogSendNotify';
import LogSendSmsComponent from './LogSendSms';
import SendingLogMailComponent from './SendingLogMail';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useSelector } from 'react-redux';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import { DISPLAY_CMS_UI_TYPE, MEMBER_TYPE } from '../../Common/constant';
import { LINK_MAP_DEFINED_API } from '../../Config/listConstants';

tableNewTheme.overrides = Object.assign({}, {
  ...tableNewTheme?.overrides, MuiTabs: {
    root: {
      marginTop: '14px',
    },
    PrivateTabIndicator: {
      root: {
        backgroundColor: 'unset',
      },
    },
  },
});

const theme = createMuiTheme({
  ...tableNewTheme,
});

function Index() {
  const [value, setValue] = useState('');
  const [typeShowLogs, setTypeShowLogs] = useState([
    { type: DISPLAY_CMS_UI_TYPE.HIDDEN, active: 'MAIL_LOG', order: -1, content: '' },
    { type: DISPLAY_CMS_UI_TYPE.HIDDEN, active: 'SMS_LOG', order: -1, content: '' },
    { type: DISPLAY_CMS_UI_TYPE.HIDDEN, active: 'PUSH_LOG', order: -1, content: '' },
  ]); // 0 show, 1 show + not link, 2 hidden

  const defineShows = [DISPLAY_CMS_UI_TYPE.ON, DISPLAY_CMS_UI_TYPE.NOT_LINK];
  const admin = useSelector((state) => state.admin.data);
  const envUICms = useSelector((state) => state.envUICms.data);

  const sendMailLog = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.MANAGEMENT_SEND_MAIL_LOG);
  const sendSmsLog = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.MANAGEMENT_SEND_SMS_LOG);
  const sendPushLog = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.MANAGEMENT_SEND_PUSH_LOG);

  const setDataTypeShowAllActive = () => {
    const tmp = typeShowLogs.map(ele => ({ ...ele, type: DISPLAY_CMS_UI_TYPE.ON }));
    setTypeShowLogs(uniqBy(tmp, 'active'));
  };

  const setDataTypeShowActive = (key = '', payload = {}) => {
    let findActive = typeShowLogs.find(ele => ele.active === key);

    if (findActive) {
      Object.assign(findActive, payload);
      setTypeShowLogs(orderBy(uniqBy(Object.assign([], [...typeShowLogs, findActive]), 'active'), ['order'], ['asc']));
    }
  };

  useEffect(() => {
    let typeSysAdmin = false;
    if (+admin?.memberType === MEMBER_TYPE.SYSTEM_ADMIN) {
      setDataTypeShowAllActive();
      typeSysAdmin = true;
    }

    // Check type show setting
    if (sendMailLog) {
      setDataTypeShowActive('MAIL_LOG', {
        type: (typeSysAdmin ? DISPLAY_CMS_UI_TYPE.ON : +sendMailLog.type),
        order: sendMailLog.order,
        content: sendMailLog.content,
      });
    }
    if (sendSmsLog) {
      setDataTypeShowActive('SMS_LOG', {
        type: (typeSysAdmin ? DISPLAY_CMS_UI_TYPE.ON : +sendSmsLog.type),
        order: sendSmsLog.order,
        content: sendSmsLog.content,
      });
    }
    if (sendPushLog) {
      setDataTypeShowActive('PUSH_LOG', {
        type: (typeSysAdmin ? DISPLAY_CMS_UI_TYPE.ON : +sendPushLog.type),
        order: sendPushLog.order,
        content: sendPushLog.content,
      });
    }

  }, [admin]);

  useEffect(() => {
    let findActive = typeShowLogs.filter(ele => ele.type === DISPLAY_CMS_UI_TYPE.ON);
    if (findActive && findActive.length) {
      setValue(findActive[0].active);
    }
  }, [typeShowLogs]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const clickTabSelected = (selected) => {
    setValue(selected.active);
  };

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Tabs
          scrollButtons="auto"
          indicatorColor="unset"
          textColor="white"
          value={value}
          onChange={handleChange}
          className="d-tab-wrapper tab-log-send-wrapper"
        >
          {typeShowLogs && typeShowLogs.map((ele, index) => (
              <>
                {defineShows?.includes(ele.type) ? (
                    <Tab disableRipple
                         key={index}
                         value={ele.active}
                         onClick={() => clickTabSelected(ele)}
                         className={`${value === ele.active ? 'btn-blue' : ''} btn-custom`}
                         label={ele.content}
                         title={ele.content} />)
                  : <div key={index}></div>}
              </>
            ),
          )}}
        </Tabs>

        <div className="mt-2">
          {value === 'MAIL_LOG' && (typeShowLogs.findIndex(ele => ele.active === 'MAIL_LOG' && ele.type === DISPLAY_CMS_UI_TYPE.ON) !== -1) && (
            <SendingLogMailComponent value={value} />
          )}
          {value === 'SMS_LOG' && (typeShowLogs.findIndex(ele => ele.active === 'SMS_LOG' && ele.type === DISPLAY_CMS_UI_TYPE.ON) !== -1) && (
            <LogSendSmsComponent value={value} />
          )}
          {value === 'PUSH_LOG' && (typeShowLogs.findIndex(ele => ele.active === 'PUSH_LOG' && ele.type === DISPLAY_CMS_UI_TYPE.ON) !== -1) && (
            <LogSendNotifyComponent value={value} />
          )}
        </div>
      </MuiThemeProvider>
    </div>
  );
}

export default Index;
