import React, { useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Button,
  IconButton,
  ListItemAvatar,
  TextareaAutosize,
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import FileUploader from 'react-firebase-file-uploader';
import moment from 'moment';
import {
  getChatMessageByConversation,
  readAllMessageUnRead,
  sendMessage,
} from '../../../../Api/chatApi';
import _ from 'lodash';
import '../styles-work.scss';
import Util from '../../../../Util/Util';
import socket from '../../../../socket';
import { toast } from 'react-toastify';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DatePicker from 'react-datepicker';
import RadioGroup from '../../../../Components/RadioGroup';
import { createChatRoomTimer } from '../../../../Api/timerAPI';
import configs from '../../../../Config/config';
import CropperDialog from '../../../../Components/CropperDialog';
import { displayOptionsOptionsRequired } from '../../../../Util/utils';
import {
  APP_ALLOW_UPLOAD_TYPE,
  CHAT_CATEGORY_TYPE,
  CHAT_MESSAGE_TYPE,
  DIR_FOLDER_IMAGE,
  DISPLAY_OPTIONS,
  HIDDEN_TYPE,
  INSPECTION_TYPE,
  MEMBER_USER_STATUS,
  READ_TYPE,
  REPLIED_STATUS,
} from '../../../../Common/constant';
import { TOAST_MESSAGE } from '../../../../Common/constant_text';
import NoPhoto from '../../../../assets/image/no_user_photo.png';
import UserDelete from '../../../../assets/image/delete_user.png';

function AppChat({ conversationId, values, viewImage }) {
  const [listMessages, setListMessages] = useState([]);
  const [text, setText] = useState('');
  const [messageSocket, setMessageSoket] = useState([]);
  const [image, setImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [croppingImage, setCroppingImage] = useState(false);
  const [fileUploader, setFileUploader] = useState();

  const inputEl = useRef(null);

  const [hourSend, setHourSend] = useState(moment().format('HH').toString());
  const [minusSend, setMinusSend] = useState(moment().format('mm').toString());
  const [dateSend, setDateSend] = useState(
    moment(new Date(moment().startOf('day')).getTime()).format('YYYY-MM-DD'),
  );

  const [timeSend, setTimeSend] = useState();
  const [timerSms, setTimerSms] = useState(`${DISPLAY_OPTIONS.OFF}`);
  const [selectedDate, setSelectedDate] = useState(
    new Date(moment().startOf('day')).getTime(),
  );

  useEffect(() => {
    getListMessagesById();
  }, [conversationId]);

  useEffect(() => {
    const readMessages = async () => {
      await readAllMessageUnRead({
        conversationId,
        userId: values.receiver.id,
      });
    };
    readMessages();
  }, [conversationId, values]);

  const getListMessagesById = async () => {
    try {
      const result = await getChatMessageByConversation({ conversationId });

      if (result.status === 200) {
        const messagesBaseDate = _(result.data.data)
          .sortBy((group) => {
            return group.createdDate;
          })
          .groupBy((item) => {
            return moment(Number(item.createdDate)).format('YYYY-MM-DD');
          })
          .value();

        setListMessages(messagesBaseDate);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const scrollToRef = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
      const list = document?.getElementById('list-messages');
      list?.scrollTo(0, list.scrollHeight);
    }
  };

  useEffect(() => {
    if (listMessages) scrollToRef(inputEl);
  }, [listMessages]);

  const loadToBottom = () => {
    scrollToRef(inputEl);
  };

  useEffect(() => {
    socket.on('receiveMessage', (data) => {
      const currentDate = moment(new Date()).format('YYYY-MM-DD');

      const dataListMessages =
        listMessages[currentDate] &&
        listMessages[currentDate].map((v) => {
          if (data.senderId === v.receiverId) {
            v.replied = REPLIED_STATUS.REPLIED; // 1
            v.read = READ_TYPE.READ; // 1
          }
          return v;
        });

      const dataMessage = {
        ...data,
        createdDate: String(data.createdDate),
        inspection: INSPECTION_TYPE.INSPECTION, // 1,
        hidden: HIDDEN_TYPE.NO_HIDDEN, // 0
        replied: REPLIED_STATUS.NOT_REPLIED, // 0
      };

      setListMessages({
        ...listMessages,
        [currentDate]: listMessages[currentDate]
          ? [...dataListMessages, dataMessage]
          : [dataMessage],
      });
    });
  }, [messageSocket, listMessages]);

  const onClickButton = async (conversationId, image) => {
    if (!image && !text) {
      return;
    }

    let data = {
      senderId: values.receiver.id,
      receiverId: values.sender.id,
      conversationId: conversationId,
      content: image ? image : text,
      type: image ? CHAT_MESSAGE_TYPE.IMAGE : CHAT_MESSAGE_TYPE.TEXT,
      status: 1,
      isUserReply: false,
    };
    if (String(timerSms) && +timerSms === DISPLAY_OPTIONS.OFF) {
      await sendMessage(data).then((response) => {
        const { data } = response;
        if (data.success) {
          setImage(null);
          setText('');
        } else {
          toast.error(data.message);
        }
      });
      socket.emit('sendMessage', data);
    } else {
      const timer = validateTimer();

      if (timer.valid) {
        data.sendTime = timeSend;
        data.chatCategory = CHAT_CATEGORY_TYPE.CHAT_INDIVIDUAL;

        await createChatRoomTimer(data)
          .then((response) => {
            const { data } = response;
            if (data.success) {
              setImage(null);
              setText('');
              toast.success(TOAST_MESSAGE.SUCCESS_ADD);
            } else {
              toast.error(TOAST_MESSAGE.ERROR_ADD);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }

    setMessageSoket(new Date());
  };

  const validateTimer = () => {
    const startDay = new Date(moment().startOf('day')).getTime();
    const checkTime = timeSend || startDay;

    if (
      checkTime &&
      checkTime < new Date().getTime() &&
      String(timerSms) &&
      +timerSms === DISPLAY_OPTIONS.ON
    ) {
      toast.error('予約時間が過去に設定されています');
      return { valid: false, timeStr: moment(checkTime).format('YYYYMMDD') };
    }

    return { valid: true, timeStr: moment(checkTime).format('YYYYMMDD') };
  };

  const onTextChange = (e) => {
    setText(e.target.value);
  };

  const uploadStartLogic = async (file) => {
    const timer = validateTimer();
    if (!timer.valid) {
      return;
    }

    if (configs.allowUpload === APP_ALLOW_UPLOAD_TYPE.SERVER) {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          setCroppingImage(reader.result);
          setShowCropper(true);
        },
        false,
      );
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const customOnChangeHandler = (event) => {
    const timer = validateTimer();
    if (!timer.valid) {
      return;
    }

    const {
      target: { files },
    } = event;

    for (let file of files) {
      if (configs.allowUpload === APP_ALLOW_UPLOAD_TYPE.SERVER) {
        uploadStartLogic(file);
      }
    }
  };

  const handleDate = (date) => {
    setSelectedDate(date);
    date = moment(date).format('YYYY-MM-DD');
    setDateSend(date);
    let sendTime = new Date(`${date} ${hourSend}:${minusSend}:00`);
    setTimeSend(sendTime.getTime());
  };

  const handleMinus = (e) => {
    let value = e.target.value;
    setMinusSend(value);
    let sendTime = new Date(`${dateSend} ${hourSend}:${value}:00`);
    setTimeSend(sendTime.getTime());
  };

  const handleHour = (e) => {
    let value = e.target.value;
    setHourSend(value);
    let sendTime = new Date(`${dateSend} ${value}:${minusSend}:00`);
    setTimeSend(sendTime.getTime());
  };

  let hour = [];
  for (let index = 0; index < 24; index++) {
    if (index < 10) {
      hour.push('0' + index);
    } else {
      hour.push(index);
    }
  }

  let minute = [];
  for (let index = 0; index < 60; index++) {
    if (index < 10) {
      minute.push('0' + index);
    } else {
      minute.push(index);
    }
  }

  const onCropImage = (url) => {
    setShowCropper(false);
    onClickButton(conversationId, url);
  };

  const cancelCropImage = () => {
    setShowCropper(false);
    setCroppingImage(null);
  };

  const handlerTimerSms = (value) => {
    setTimerSms(`${value}`);
  };

  return (
    <>
      <div
        className={`d-content-chat chatFrame`}
        id="list-messages"
        style={{ position: 'relative' }}
      >
        {listMessages &&
          Object.entries(listMessages).map(([index, messageDay]) => (
            <div>
              <div className="textCenter">
                <span>{index}</span>
              </div>
              {messageDay.map((messageTime) => (
                <React.Fragment>
                  {messageTime.senderId === values.receiver.id ? (
                    <div className="flex d-wrapper-right">
                      <div className="myMessageWrapper">
                        <span className="timeSpan">
                          {moment(Number(messageTime.createdDate)).format(
                            'HH:mm',
                          )}
                          <p style={{ marginBottom: 0 }}>
                            {Util.hiddenToText(messageTime.hidden)}
                          </p>
                          <p style={{ marginBottom: 0 }}>
                            {Util.readIdToText(messageTime.read)}
                          </p>
                          <p style={{ marginBottom: 0 }}>
                            {Util.repliedToText(messageTime.replied)}
                          </p>
                          <p style={{ marginBottom: 0 }}>
                            {Util.inspectionToText(messageTime.inspection)}
                          </p>
                        </span>
                        {messageTime.type === CHAT_MESSAGE_TYPE.IMAGE ? (
                          <img
                            onClick={() => viewImage(messageTime.content)}
                            className="contentImage"
                            src={messageTime.content}
                            alt="contentImage"
                          />
                        ) : (
                          <div className="messageFrame">
                            {messageTime.content}
                          </div>
                        )}
                      </div>
                      <ListItemAvatar>
                        <Avatar
                          alt="Avatar"
                          src={
                            values?.receiver?.memberStatus ===
                            MEMBER_USER_STATUS.IN_ACTIVE
                              ? UserDelete
                              : values.receiver.avatar?.url || NoPhoto
                          }
                        />
                      </ListItemAvatar>
                    </div>
                  ) : (
                    <div className="flex d-wrapper-left">
                      <ListItemAvatar>
                        <Avatar
                          alt="Avatar"
                          src={
                            values?.sender?.memberStatus ===
                            MEMBER_USER_STATUS.IN_ACTIVE
                              ? UserDelete
                              : values.sender.avatar?.url || NoPhoto
                          }
                        />
                      </ListItemAvatar>
                      <div className="userMessageWrapper">
                        {messageTime.type === CHAT_MESSAGE_TYPE.IMAGE ? (
                          <img
                            onClick={() => viewImage(messageTime.content)}
                            className="contentImage"
                            src={messageTime.content}
                            alt="contentImage"
                          />
                        ) : (
                          <div className="messageFrame">
                            {messageTime.content}
                          </div>
                        )}
                        <span className="timeSpan">
                          {moment(Number(messageTime.createdDate)).format(
                            'HH:mm',
                          )}
                          <p style={{ marginBottom: 0 }}>
                            {Util.hiddenToText(messageTime.hidden)}
                          </p>
                          <p style={{ marginBottom: 0 }}>
                            {Util.readIdToText(Number(messageTime.read))}
                          </p>
                          <p style={{ marginBottom: 0 }}>
                            {Util.repliedToText(messageTime.replied)}
                          </p>
                          <p style={{ marginBottom: 0 }}>
                            {Util.inspectionToText(messageTime.inspection)}
                          </p>
                        </span>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          ))}
        <div style={{ float: 'left', clear: 'both' }} ref={inputEl} />
        <div
          style={{
            position: 'fixed',
            right: 62,
            bottom: 220,
            zIndex: 10000,
            background: 'rgb(0,0,0,0.1)',
            borderRadius: '50%',
          }}
        >
          <IconButton color="primary" onClick={() => loadToBottom()}>
            <KeyboardArrowDownIcon color="action" />
          </IconButton>
        </div>
      </div>

      <div
        className="d-content-action chatControlFrame"
        style={{ display: !values?.sender?.id && 'none' }}
      >
        <div className="d-flex d-justify-space-between">
          <label
            style={{
              alignSelf: 'flex-end',
              margin: 0,
              marginRight: 5,
              marginLeft: -5,
            }}
          >
            <FileUploader
              hidden
              accept="image/*"
              randomizeFilename
              onClick={(e) => (e.target.value = null)}
              onUploadStart={uploadStartLogic}
              onChange={customOnChangeHandler}
              ref={(instance) => setFileUploader(instance)}
            />
            <AttachFileIcon className="attachIcon" />
          </label>

          <div id="wrapper-text-chat" className="w-100">
            <div
              className="wrapperSendMessage"
              style={{ border: '1px solid lightgray' }}
            >
              <TextareaAutosize
                name
                className="textArea"
                rowsMin={3}
                rowsMax={3}
                onChange={onTextChange}
                value={text}
                disabled={image}
              />
            </div>
          </div>
          <label style={{ alignSelf: 'flex-end', margin: 0 }}>
            <Button
              variant="contained"
              color="primary"
              className="btnCustomer mr-0 ml-2"
              onClick={() => onClickButton(conversationId)}
              // disabled={!image && !text}
            >
              送信
            </Button>
          </label>
        </div>
        <div style={{ textAlign: 'end', marginRight: 70 }}>
          <span>相手が年齢認証しないと送信できない場合があります</span>
        </div>

        <div className="timer-container mt-2">
          <div className="mt-3 mb-1">
            <span>タイマー日時</span>
          </div>
          <div className="d-flex align-items-center">
            <div className="datePicker">
              <DatePicker
                selected={selectedDate}
                className="date"
                onChange={(e) => handleDate(e)}
                locale="ja"
                dateFormat="yyyy/MM/dd"
                popperPlacement="top-end"
                disabled={
                  !(String(timerSms) && +timerSms === DISPLAY_OPTIONS.ON)
                }
              />
              <select
                defaultChecked={hourSend}
                onChange={(e) => handleHour(e, 'start_hour')}
                className="hour"
                disabled={
                  !(String(timerSms) && +timerSms === DISPLAY_OPTIONS.ON)
                }
              >
                {hour.map((value) => (
                  <option
                    selected={value.toString() === hourSend}
                    key={value}
                    value={value}
                  >
                    {value}
                  </option>
                ))}
              </select>
              <select
                onChange={(e) => handleMinus(e, 'start_minus')}
                className="minus"
                disabled={
                  !(String(timerSms) && +timerSms === DISPLAY_OPTIONS.ON)
                }
              >
                {minute.map((value) => (
                  <option
                    selected={value.toString() === minusSend}
                    key={value}
                    value={value}
                  >
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <div className="display-setting-timer d-un-width">
              <RadioGroup
                name="displaySetting"
                defaultValue={timerSms}
                options={displayOptionsOptionsRequired()}
                onChange={(e) => handlerTimerSms(e.target.value)}
              />
            </div>
          </div>
        </div>

        {showCropper && (
          <CropperDialog
            image={croppingImage}
            onCrop={onCropImage}
            onClose={cancelCropImage}
            dir={`${DIR_FOLDER_IMAGE.CHAT}/${values?.sender?.id}`}
            type={'ope_work_page_management_app_chat'}
          />
        )}
      </div>
    </>
  );
}

export default AppChat;
