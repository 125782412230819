import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEnvironment } from '../../../Api/environmentApi';
import LoadingBar from '../../../Components/LoadingBar';
import Pagination from '../../../Components/Pagination';
import ScheduleMailDetailModal from './ScheduleMailDetailModal';
import ScheduleMailList from './ScheduleMailList';
import TimerMailSearchBox from './TimerMailSearchBox';
import {
  estimateScheduleMailReceivers,
  loadScheduleMail,
} from '../../../Redux/Actions/scheduleMail';

const pageSize = 50;

function Index() {
  const loading = useSelector((state) => state.scheduleMail.loading);
  const loadingReceivers = useSelector(
    (state) => state.scheduleMail.loadingReceivers,
  );
  const timerMail = useSelector((state) => state.scheduleMail.items);
  const totalRow = useSelector((state) => state.scheduleMail.totalRow);
  const [filter, setFilter] = useState({
    timer: 0,
    sort: { sendTime: 'DESC' },
  });
  const [pageIndex, setPageIndex] = useState(1);
  const [timerMailDetail, setTimerMailDetail] = useState(null);

  const [fromMails, setFromMails] = useState([]);
  const [fromMailAddress, setFromMailAddress] = useState(null);

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (filter.sort[sortByKey] === undefined) {
      clientRules[sortByKey] = 'DESC';
    }
    if (filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadScheduleMail({ pageIndex, pageSize, ...filter }));
  }, [dispatch, pageIndex, filter]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadScheduleMail({ ...filter, pageIndex, pageSize }));
  };

  const handleClose = () => {
    setTimerMailDetail(null);
  };

  const reload = () => {
    dispatch(loadScheduleMail({ ...filter, pageIndex, pageSize }));
  };

  const getEstimateReceivers = () => {
    dispatch(
      estimateScheduleMailReceivers({
        ...filter,
        pageIndex,
        pageSize,
        estimateReceivers: true,
      }),
    );
  };

  const handleClickDetail = (item) => {
    getEnvironment({}).then((res) => {
      const env = res;
      const sendGridAddress = Object.keys(env.data.data)
        .filter((t) => t.indexOf('sendgridAddress') > -1)
        .map((t) => {
          return {
            key: t,
            value: env.data.data[t],
          };
        });
      setFromMails(sendGridAddress);
      setFromMailAddress(
        sendGridAddress.find((t) => t.key === item?.fromAddress)?.value,
      );
      setTimerMailDetail(item);
    });
  };

  const handleSearch = (searchFilter) => {
    setFilter({ ...searchFilter, sort: filter.sort });
    setPageIndex(1);
  };

  const onClear = () => setFilter({ timer: 0, sort: { sendTime: 'DESC' } });

  return (
    <div>
      <LoadingBar show={loading} />
      <TimerMailSearchBox
        loadingReceivers={loadingReceivers}
        onSubmit={handleSearch}
        onClear={onClear}
        estimateReceivers={getEstimateReceivers}
      />
      {timerMailDetail && (
        <ScheduleMailDetailModal
          onClose={handleClose}
          scheduleMail={timerMailDetail}
          reload={reload}
          fromMails={fromMails}
          fromMailAddress={fromMailAddress}
        />
      )}
      <ScheduleMailList
        loading={loading}
        loadingReceivers={loadingReceivers}
        logSendMail={timerMail}
        pageIndex={pageIndex}
        pageSize={pageSize}
        filter={filter}
        onSort={onSort}
        handleClickDetail={handleClickDetail}
      />
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default Index;
