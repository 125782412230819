import { Link } from '@material-ui/core';
import {
  Card,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import SortArrows from '../../../Components/SortArrows';
import { tableNewTheme } from '../../../ConfigUI/tableTheme';
import NoPhoto from '../../../assets/image/no_user_photo.png';
import Util from '../../../Util/Util';

import SearchBox from './SearchBox';
import LoadingBar from '../../../Components/LoadingBar';

function LogSendList(props) {
const {
  logSendNoti = [],
  pageIndex,
  pageSize,
  loading,
  filter = { sort: {} },
  onSort = (sortByKey) => {},
  handleClickDetail,
  onSearch,
  onClear,
  labelDb
} = props;

  return (
    <div className="align-top">
    <SearchBox onSubmit={onSearch} onClear={onClear} labelDb={labelDb}/>
    <LoadingBar show={loading} />

      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>
                  {/* <SortArrows
                    sortObject={filter.sort}
                    text="メールアドレス"
                    name="user.phoneNumber"
                    onClick={onSort}
                  /> */}
                  電話番号
                </TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="user.nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="性別"
                    name="user.gender"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="タイトル"
                    name="LogSendNoti.title"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="本文"
                    name="LogSendNoti.content"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="日時"
                    name="LogSendNoti.createdDate"
                    onClick={onSort}
                    defaultName="LogSendNoti.createdDate"
                  />
                </TableCell>
                <TableCell>ボタン</TableCell>
              </TableRow>

              {logSendNoti.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell>
                    {(pageIndex - 1) * pageSize + index + 1}
                  </TableCell>
                  <TableCell>
                    <span>{item.user?.phoneNumber}</span>
                  </TableCell>
                  <TableCell align="center">
                    <img
                      className="imgRound"
                      src={item.user?.avatar ? item.user.avatar.url : NoPhoto}
                      alt=""
                    />
                  </TableCell>
                  <TableCell>{item.user?.nickName}</TableCell>
                  <TableCell>
                    {Util.genderIdToText(item.user?.gender)}
                  </TableCell>

                  <TableCell>{item.title}</TableCell>
                  <TableCell>{item.content}</TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(item.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                    </time>
                  </TableCell>
                  <TableCell  className="d-color-link" style={{ cursor: 'pointer' }}>
                    <Link onClick={() => handleClickDetail(item)} className="d-color-link">表示</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
    </div>
  );
}

export default LogSendList;
