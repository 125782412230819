import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import LogSendListItem from './LogSendListItem';
import SortArrows from '../../../Components/SortArrows';
import { Card } from '@material-ui/core';
import { tableNewTheme } from '../../../ConfigUI/tableTheme';
import SearchBox from './SearchBox';
import LoadingBar from '../../../Components/LoadingBar';

function LogSendList(props) {
const {
  logSendSms = [],
  pageIndex,
  pageSize,
  loading,
  filter = { sort: {} },
  onSort = (sortByKey) => {},
  handleClickDetail,
  onSearch,
  onClear,
  labelDb
} = props

  return (
    <div className="align-top">
      <SearchBox onSubmit={onSearch} onClear={onClear} labelDb={labelDb}/>
      <LoadingBar show={loading} />

      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号"
                    name="user.phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="user.nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="性別"
                    name="user.gender"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="本文"
                    name="logSendSms.content"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="日時"
                    name="logSendSms.createdDate"
                    onClick={onSort}
                    defaultName="logSendSms.createdDate"
                  />
                </TableCell>
                <TableCell>ボタン</TableCell>
              </TableRow>

              {logSendSms.map((item, index) => (
                <LogSendListItem
                  key={item.id}
                  logSendSms={item}
                  no={(pageIndex - 1) * pageSize + index + 1}
                  handleClickDetail={handleClickDetail}
                />
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
    </div>
  );
}

export default LogSendList;
