import { LINK_DISPLAY_PAGE_AFTER_LOGIN } from '../Config/listConstants';
import {
  charaStatus,
  chatUserTypes,
  confirmTypes,
  contactTypes,
  displayOptions,
  emailAddressMainStatus,
  emailSendCategory,
  errorApiText,
  genderCharacters,
  hasDepositedOption,
  hiddenStatus,
  imageProfStatus,
  imageTypes,
  inspectionStatus,
  jobEntryMemberType,
  jobPublishingType,
  likeMatchTypes,
  loginControlArray,
  memberAgentType,
  memberArray,
  memberCategoryType,
  memberImageType,
  memberTypeArray,
  needOtp,
  payingTypeArray,
  paymentTypeArray,
  profImageMainTypes,
  profImageSubTypes,
  readStatus,
  registerStatus,
  registerType,
  repliedStatus,
  scheduleCronjobStatusOptions,
  scheduleSendStatusOptions,
  scheduleSendTypeOptions,
  settingNotify,
  sexArray,
  standBy,
  timerSendingTypes,
  timerSends,
  timerSentTypes,
  timerType,
  twilioReceiverStatusTypes,
  twilioResultTypes,
  twilioStatusTypes,
  typeConnection,
} from '../Common/constant_text';
import {
  MEMBER_TYPE,
  REGEX_ALPHABET,
  REGEX_NUMBER,
  REGISTER_STATUS_MEMBER,
  TWILIO_RECEIVER_STATUS_TYPE,
  TWILIO_RESULT_TYPE,
  TWILIO_STATUS_TYPE,
} from '../Common/constant';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

export const pathDisplayPageAfterLogin = (numberPage) => {
  if (LINK_DISPLAY_PAGE_AFTER_LOGIN[numberPage]) {
    return LINK_DISPLAY_PAGE_AFTER_LOGIN[numberPage];
  }

  // Default show page home
  return '/home';
};

export const convertCharaStatusText = (numberCharaStatus) => {
  return charaStatus.find((ele) => ele.id === +numberCharaStatus)?.value;
};

export const convertInspectionStatusText = (number) => {
  return inspectionStatus.find((ele) => ele.id === +number)?.value;
};

export const convertDisplayOptionsText = (number) => {
  return displayOptions.find((ele) => ele.id === +number)?.value;
};

export const convertRegisterTypeText = (number) => {
  return registerType.find((ele) => ele.id === +number)?.value;
};

export const convertRegisterStatusText = (number) => {
  return registerStatus.find((ele) => ele.id === +number)?.value;
};

export const convertStandByText = (number) => {
  return standBy.find((ele) => ele.id === +number)?.value;
};

export const convertImageProfStatusText = (number) => {
  return imageProfStatus.find((ele) => ele.id === +number)?.value;
};

export const convertEmailAddressMainStatusText = (number) => {
  return emailAddressMainStatus.find((ele) => ele.id === +number)?.value;
};

export const convertNeedOtpText = (number) => {
  return needOtp.find((ele) => ele.id === +number)?.value;
};

export const convertReadStatusText = (number) => {
  return readStatus.find((ele) => ele.id === +number)?.value;
};

export const convertRepliedStatusText = (number) => {
  return repliedStatus.find((ele) => ele.id === +number)?.value;
};

export const convertHiddenStatusText = (number) => {
  return hiddenStatus.find((ele) => ele.id === +number)?.value;
};

export const jobPublishingTypeText = (number) => {
  return jobPublishingType.find((ele) => ele.id === +number)?.value;
};

export const convertMemberCategoryTypeText = (number) => {
  return memberCategoryType.find((ele) => ele.id === +number)?.value;
};

export const convertMemberStatusText = (number) => {
  return memberArray.find((ele) => ele.id === +number)?.value;
};

export const convertTimerSendingTypeText = (number) => {
  return timerSendingTypes.find((ele) => ele.id === +number)?.value;
};

export const convertProfImageSubTypesText = (number) => {
  return profImageSubTypes.find((ele) => ele.id === +number)?.value;
};

export const convertProfImageMainTypesText = (number) => {
  return profImageMainTypes.find((ele) => ele.id === +number)?.value;
};

export const convertTwilioStatusTypeText = (value) => {
  if (value === null) {
    value = TWILIO_STATUS_TYPE.NULL;
  }

  return twilioStatusTypes.find((ele) => ele.id === +value)?.value;
};

export const convertTwilioResultTypeText = (value) => {
  if (value === null) {
    value = TWILIO_RESULT_TYPE.NULL;
  }

  return twilioResultTypes.find((ele) => ele.id === value)?.value;
};

export const convertTwilioReceiverStatusTypeText = (value) => {
  if (value === null) {
    value = TWILIO_RECEIVER_STATUS_TYPE.NULL;
  }

  return twilioReceiverStatusTypes.find((ele) => ele.id === +value)?.value;
};

export const convertMemberTypeArrayText = (value) => {
  return memberTypeArray.find((ele) => ele.id === +value)?.value;
};

export const convertJobEntryMemberTypeArrayText = (value) => {
  return jobEntryMemberType.find((ele) => ele.id === +value)?.value;
};

export const memberAgentTypeArrayText = (value) => {
  return memberAgentType.find((ele) => ele.id === +value)?.value;
};

export const convertConfirmTypeText = (value) => {
  return confirmTypes.find((ele) => ele.id === +value)?.value;
};

export const convertContactTypeText = (value) => {
  return contactTypes.find((ele) => ele.id === +value)?.value;
};

export const displayTextScheduleSendStatusOptions = (value) => {
  return scheduleSendStatusOptions.find((e) => e.value === +value).label;
};
export const displayTextScheduleSendTypeOptions = (value) => {
  return scheduleSendTypeOptions.find((e) => e.value === +value).label;
};
export const displayTextScheduleCronjobTypeOptions = (value) => {
  return scheduleCronjobStatusOptions.find((e) => e.value === +value).label;
};

// export const convertNeedOtpCustomText = (value) => {
//   return needOtpCustoms.find(ele => ele.id === +value)?.value;
// };

export const renderStatusAgeConfirm = (value) => {
  if (!value) {
    return '全て';
  }
  return inspectionStatus.find((ele) => ele.id === +value)?.value;
};

export const imageTypeOptions = () => {
  return [
    { value: '', label: '全て' },
    ...imageTypes.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const contactTypeOptions = () => {
  return [
    { value: '', label: '全て' },
    ...contactTypes.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const memberCategoryTypeOptions = () => {
  return [
    { value: '', label: '全て' },
    ...memberCategoryType.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const memberImageTypeOptions = () => {
  return [
    { value: '', label: '全て' },
    ...memberImageType.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const memberCategoryTypeRequireOptions = () => {
  return [
    ...memberCategoryType.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const confirmTypeOptions = () => {
  return [
    { value: '', label: '全て' },
    ...confirmTypes.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const chatUserTypeOptions = () => {
  return [
    { value: '', label: '全て' }, // none
    ...chatUserTypes.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const imageProfStatusOptions = () => {
  return [
    { value: '', label: '全て' }, // none
    ...imageProfStatus.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const twilioStatusTypeOptions = () => {
  return [
    { value: '', label: '全て' }, // none
    ...twilioStatusTypes.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const twilioResultTypeOptions = () => {
  return [
    { value: '', label: '全て' }, // none
    ...twilioResultTypes.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const twilioReceiverStatusTypeOptions = () => {
  return [
    { value: '', label: '全て' }, // none
    ...twilioReceiverStatusTypes.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const likeMatchTypeOptions = () => {
  return [
    { value: '', label: '全て' },
    ...likeMatchTypes.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const sexOptions = () => {
  return [
    { value: '', label: '全て' },
    ...sexArray.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const sexOptionsRequired = () => {
  return [
    ...sexArray.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const inspectionStatusOptionsNormal = () => {
  return [
    { value: '', label: '全て' },
    ...inspectionStatus.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const repliedStatusOptionsNormal = () => {
  return [
    { value: '', label: '全て' },
    ...repliedStatus.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const readStatusOptionsNormal = () => {
  return [
    { value: '', label: '全て' },
    ...readStatus.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const hiddenStatusOptionsNormal = () => {
  return [
    { value: '', label: '全て' },
    ...hiddenStatus.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const hiddenStatusRequired = () => {
  return [
    ...hiddenStatus.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const jobPublishingTypeRequired = () => {
  return [
    ...jobPublishingType.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const loginControlArrayOptionsNormal = () => {
  return [
    { value: '', label: '全て' },
    ...loginControlArray.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const loginControlArrayOptionsRequired = () => {
  return [
    ...loginControlArray.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const charaStatusOptionsNormal = () => {
  return [
    { value: '', label: '全て' },
    ...charaStatus.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const charaStatusOptionsRequired = () => {
  return [
    ...charaStatus.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const memberArrayOptionsRequired = () => {
  return [
    ...memberArray.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const memberStatusOptionsRequired = () => {
  return [
    { value: '', label: '全て' },
    ...memberArray.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const typeConnectionsRequired = () => {
  return [
    ...typeConnection.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const memberTypeAllOptions = () => {
  return [
    { value: '', label: '全て' },
    ...memberTypeArray.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const memberTypeOfUserOptions = () => {
  return [
    { value: '', label: '全て' },
    ...memberTypeArray
      .filter((ele) => ![MEMBER_TYPE.PLAN_USER].includes(ele.id)) // Not filter member of job
      .map((ele) => {
        return { value: ele.id, label: ele.value };
      }),
  ];
};

export const standByOptions = () => {
  return [
    { value: '', label: '全て' },
    ...standBy.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const standByRequired = () => {
  return [
    ...standBy.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const memberTypeAllOptionsRequired = () => {
  return [
    ...memberTypeArray.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const memberTypeOfUserOptionsRequired = () => {
  return [
    ...memberTypeArray
      .filter((ele) => ![MEMBER_TYPE.PLAN_USER].includes(ele.id)) // Not filter member of job
      .map((ele) => {
        return { value: ele.id, label: ele.value };
      }),
  ];
};

export const memberAgentTypeRequired = () => {
  return [
    ...memberAgentType.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const displayOptionsOptionsRequired = () => {
  return [
    ...displayOptions.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const needOtpOptions = () => {
  return [
    { value: '', label: '全て', selected: true },
    ...needOtp.map((ele) => {
      return { value: ele.id, label: ele.value, selected: ele.selected };
    }),
  ];
};

export const convertSettingNotifyText = (idStr) => {
  return settingNotify.find((ele) => ele.id === idStr)?.value;
};

export const needOtpOptionsRequired = () => {
  return [
    ...needOtp.map((ele) => {
      return { value: ele.id, label: ele.value, selected: ele.selected };
    }),
  ];
};

export const emailAddressMainStatusOptions = () => {
  return [
    { value: '', label: '全て', selected: true },
    ...emailAddressMainStatus.map((ele) => {
      return { value: ele.id, label: ele.value, selected: ele.selected };
    }),
  ];
};

export const emailAddressMainStatusRequired = () => {
  return [
    ...emailAddressMainStatus.map((ele) => {
      return { value: ele.id, label: ele.value, selected: ele.selected };
    }),
  ];
};

export const hasDepositedOnTheDayOptions = () => {
  return [
    ...hasDepositedOption.map((ele) => {
      return { value: ele.id, label: ele.value, selected: ele.selected };
    }),
  ];
};

export const registerStatusOptions = () => {
  return [
    { value: '', label: '全て', selected: true },
    ...registerStatus.map((ele) => {
      return { value: ele.id, label: ele.value, selected: ele.selected };
    }),
  ];
};

export const registerStatusRequired = () => {
  return [
    ...registerStatus.map((ele) => {
      return { value: ele.id, label: ele.value, selected: ele.selected };
    }),
  ];
};

export const registerTypeOptions = () => {
  return [
    { value: '', label: '全て', selected: true },
    ...registerType.map((ele) => {
      return { value: ele.id, label: ele.value, selected: ele.selected };
    }),
  ];
};

export const registerTypeRequired = () => {
  return [
    ...registerType.map((ele) => {
      return { value: ele.id, label: ele.value, selected: ele.selected };
    }),
  ];
};

export const timerSendOptions = () => {
  return [
    { value: '', label: '全て', selected: true },
    ...timerSends.map((ele) => {
      return { value: ele.id, label: ele.value, selected: ele.selected };
    }),
  ];
};

export const timerSentTypeOptions = () => {
  return [
    { value: '', label: '全て', selected: true },
    ...timerSentTypes.map((ele) => {
      return { value: ele.id, label: ele.value, selected: ele.selected };
    }),
  ];
};

export const inspectionStatusRequired = () => {
  return [
    ...inspectionStatus.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const emailSendCategoryRequired = () => {
  return [
    ...emailSendCategory.map((ele) => {
      return { value: ele.id, label: ele.value };
    }),
  ];
};

export const paymentTypeOptionsRequired = () => {
  return [
    ...paymentTypeArray.map((ele) => {
      return { value: ele.id, label: ele.value, name: ele.value };
    }),
  ];
};

export const payingTypeOptionsRequired = () => {
  return [
    ...payingTypeArray.map((ele) => {
      return { value: ele.id, label: ele.value, name: ele.value };
    }),
  ];
};

export const searchBoxGenders = () => {
  return [
    { value: '', label: '全て' },
    ...sexArray.map((ele) => {
      return {
        label: ele.value,
        value: ele.id,
      };
    }),
    ...genderCharacters.map((ele) => {
      return {
        label: ele.value,
        value: ele.id,
      };
    }),
  ];
};

export const changeAllLinksForContent = (classRoot) => {
  const elementLinks = document.querySelectorAll(`.${classRoot} a`);

  for (let i = 0; i < elementLinks.length; i++) {
    elementLinks[i].href = '#';
    elementLinks[i].removeAttribute('target');
    elementLinks[i].style.cssText = `pointer-events:none;`;
  }
};

export const checkFullWidth = (str = '') => {
  return str && str.match(/[\uff00-\uffff\u3040-\u309f\u30a0-\u30ff]/g);
};

export const checkHalfWidth = (str = '') => {
  return str && str.match(/[\u0000-\u00ff]/g);
};

export const validateFullWidthVsNumber = (str = '', callBack) => {
  callBack(
    str &&
      (!!(checkFullWidth(str) ?? []).length || !REGEX_NUMBER.test(String(str))),
  );
};

export const validateFullWidthVsAlphabet = (str = '', callBack) => {
  callBack(
    str &&
      (!!(checkFullWidth(str) ?? []).length ||
        !REGEX_ALPHABET.test(String(str))),
  );
};

export const convertEnvInfo = (data, filter, regex = false) => {
  if (!data || !Array.isArray(data) || !data.length) {
    return {};
  }

  const objKey = data.reduce(
    (items, curr) => ((items[curr.code] = curr), items),
    {},
  );

  if (!regex && filter && Array.isArray(filter) && filter.length) {
    Object.keys(objKey)
      .filter((key) => {
        return !filter.includes(key);
      })
      .forEach((key) => delete objKey[key]);
  }

  if (regex && filter && Array.isArray(filter) && filter.length) {
    for (let key in objKey) {
      for (let item of filter) {
        if (!new RegExp(item).test(key)) {
          delete objKey[key];
        }
      }
    }
  }

  return objKey;
};

export const accessAndPassDataIframe = (
  iframeId = '',
  type = '',
  data,
  timer = 300,
) => {
  const iframe = document.getElementById(iframeId);
  if (!iframe || !type) {
    return;
  }
  setInterval(() => {
    if (iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          type,
          ...data,
        },
        '*',
      );
    }
  }, timer);
};

export const showToastErrorByCodeApi = (code) => {
  const msg = errorApiText[code] ?? code;

  toast.error(msg);
};

export const fileDataType = {
  fileName: null,
  fileContent: null,
  file: null,
  pathUpload: null,
  options: {},
};

export const registerStatusUserOptionsRequired = () => {
  return [
    ...registerStatus
      .filter((ele) =>
        [
          REGISTER_STATUS_MEMBER.NO_VERIFY_PHONE_NUMBER,
          REGISTER_STATUS_MEMBER.VERIFY_PHONE_NUMBER,
        ].includes(ele.id),
      )
      .map((ele) => {
        return { value: ele.id, label: ele.value };
      }),
  ];
};

export const removeSession = () => {
  Cookies.remove('token');
  Cookies.remove('refreshToken');
  Cookies.remove('operatorId');
  Cookies.remove('agentId');
  localStorage.removeItem('operatorId');
  localStorage.removeItem('agentId');
  localStorage.removeItem('userId');
  localStorage.removeItem('memberAdminId');
  localStorage.removeItem('keepSidenav');
  localStorage.removeItem('homePage');
  localStorage.removeItem('userLoginId');
};

export const replaceMultipleStrings = (inputString, replacements) => {
  let outputString = inputString;

  for (const [search, replace] of replacements) {
    while (outputString.includes(search)) {
      outputString = outputString.replace(search, replace);
    }
  }

  return outputString;
};
