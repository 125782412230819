import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  FormLabel,
} from '@material-ui/core';
import SearchPresetSelect from '../../UserSearch/shared/SearchPresetSelect';
import React, { useState } from 'react';
import { Col, Label, Row } from 'reactstrap';
import Util from '../../../Util/Util';
import RemoveModal from './RemoveModal';
import { toast } from 'react-toastify';
import CustomEditor from '../../../Components/CustomEditor';
import { TEXT_HTML_MANAGEMENT_TIMER_STANDBY_MAIL_LINK_MODAL } from '../../../Common/modal_text_info';
import {
  TOAST_MESSAGE,
  USER_DETAIL_MESSAGES,
  scheduleSendStatusOptions,
  scheduleTimerOptions,
} from '../../../Common/constant_text';
import { updateScheduleMail } from '../../../Api/scheduleAPI';
import { useSelector } from 'react-redux';
import RadioGroup from '../../../Components/RadioGroup';
import styles from '../shared/scheduleDetailModal.module.scss';
import classNames from 'classnames';
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';
import {
  displayTextScheduleCronjobTypeOptions,
  displayTextScheduleSendTypeOptions,
} from 'Util/utils';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'; // ES 2015

dayjs.extend(duration);

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

const stylehrleft = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const stylehrright = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};

export default function ScheduleMailDetailModal({
  onClose,
  scheduleMail,
  reload,
  fromMails,
  fromMailAddress,
}) {
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [data, setData] = useState({});
  const [selectedPresets, setSelectedPresets] = useState(null);
  const { data: fetchedPresets } = useSelector((state) => state.searchPresets);

  const handleChange = (e) => {
    console.log(e);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setConfirmVisible(false);
  };

  const handleRemove = () => {
    setConfirmVisible(true);
  };

  const handleUpdate = async () => {
    await updateScheduleMail({
      ...data,
      id: scheduleMail.id,
      searchResult:
        (selectedPresets &&
          fetchedPresets.find((e) => e.id === selectedPresets)?.content) ||
        scheduleMail.searchResult,
    }).then((response) => {
      const { data } = response;
      if (data.success) {
        onClose();
        reload();
        toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_EDIT);
      }
    });
  };

  const handleFromMailAddress = (e) => {
    data['fromAddress'] =
      e.target[e.target.selectedIndex].getAttribute('data-key');
    setData(data);
  };

  const handleChangeSelectPresets = (e) => {
    setSelectedPresets(e.target.value);
  };

  const onChangeContent = (event) => {
    data['content'] = event.editor.getData();
    setData(data);
  };

  const handleClickCopyIcon = (event, text) => {
    event.preventDefault();
    try {
      toast.success(USER_DETAIL_MESSAGES.COPY_ID_SUCCESS);
      navigator.clipboard.writeText(text);
    } catch (err) {
      toast.error(USER_DETAIL_MESSAGES.COPY_ID_FAILED);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      {confirmVisible && (
        <RemoveModal
          visible={confirmVisible}
          onClose={handleClose}
          timerMailId={scheduleMail?.id}
          reload={reload}
          onCloseDetail={onClose}
        />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={!!scheduleMail}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        className={classNames(styles.modal, 'd-modal-wrapper')}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: '10px',
          }}
        >
          <DialogTitle
            id="max-width-dialog-title"
            className={'d-title-modal-custom'}
          >
            スケジュール送信メール-詳細
          </DialogTitle>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0 24px',
              gap: '8px',
            }}
          >
            <Button
              variant="contained"
              className="btn-custom btn-red "
              onClick={onClose}
            >
              閉じる
            </Button>
            <Button
              variant="contained"
              className="btn-custom btn-blue "
              onClick={handleUpdate}
            >
              更新
            </Button>
          </div>
        </div>
        <div className={'d-title-info-box'}>
          <div
            className={'text mb-0'}
            dangerouslySetInnerHTML={{
              __html: TEXT_HTML_MANAGEMENT_TIMER_STANDBY_MAIL_LINK_MODAL,
            }}
          ></div>
        </div>

        <DialogContent>
          <Row>
            <Col xs={6}>
              <Card className="card card-body mb-2">
                <Row>
                  <Col xs={12}>
                    <FormLabel component="legend">設定名</FormLabel>
                    <textarea
                      rows="3"
                      defaultValue={scheduleMail.label}
                      style={{ width: '100%', padding: 10 }}
                      name="label"
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs={12}>
                    <FormLabel component="legend">タイトル</FormLabel>
                    <textarea
                      rows="1"
                      defaultValue={scheduleMail.title}
                      style={{ width: '100%', padding: 10 }}
                      name="title"
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={12}>
                    <FormLabel component="legend">本文</FormLabel>
                    <CustomEditor
                      name="content"
                      rows="3"
                      initData={scheduleMail.content}
                      data=""
                      onChange={onChangeContent}
                      style={{ width: '100%', marginBottom: '20px' }}
                      maxLength={500}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs={12}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <FormLabel component="legend">Search Result</FormLabel>
                      <Button
                        onClick={(e) =>
                          handleClickCopyIcon(
                            e,
                            JSON.stringify(scheduleMail.searchResult, null, 2),
                          )
                        }
                        style={{ padding: '0', minWidth: 'fit-content' }}
                      >
                        <FileCopyOutlined style={{ fontSize: 18 }} />
                      </Button>
                    </div>
                    <div
                      style={{
                        height: '100px',
                        border: '1px solid gray',
                        overflow: 'hidden',
                      }}
                    >
                      {JSON.stringify(scheduleMail.searchResult, null, 2)}
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={6}>
              <Card className="card card-body mb-2">
                <Row>
                  <Label xs={6} style={style}>
                    <p>配信カテゴリー</p>
                    <hr style={stylehrleft} />
                  </Label>
                  <Label xs={6} style={style}>
                    {
                      scheduleTimerOptions.find(
                        (e) => e.value === +scheduleMail.category,
                      )?.label
                    }
                    <hr style={stylehrright} />
                  </Label>
                  <Label xs={6} style={style}>
                    <p>送信間隔（分）</p>
                    <hr style={stylehrleft} />
                  </Label>
                  <Label xs={6} style={style}>
                    {scheduleMail.frameTime &&
                      `${dayjs.duration(+scheduleMail.frameTime).asMinutes()}分`}
                    <hr style={stylehrright} />
                  </Label>
                  <Label xs={6} style={style}>
                    <p>次回送信日時</p>
                    <hr style={stylehrleft} />
                  </Label>
                  <Label xs={6} style={style}>
                    {Util.timestampToDate(
                      scheduleMail.sendTime,
                      'YYYY/MM/DD HH:mm:ss',
                    )}
                    <hr style={stylehrright} />
                  </Label>
                  <Label xs={6} style={style}>
                    <p>最終送信日時</p>
                    <hr style={stylehrleft} />
                  </Label>
                  <Label xs={6} style={style}>
                    {Util.timestampToDate(
                      scheduleMail.lastSentDate,
                      'YYYY/MM/DD HH:mm:ss',
                    )}

                    <hr style={stylehrright} />
                  </Label>
                  <Label xs={6} style={style}>
                    <p>登録日</p>
                    <hr style={stylehrleft} />
                  </Label>
                  <Label xs={6} style={style}>
                    {Util.timestampToDate(
                      scheduleMail.createdDate,
                      'YYYY/MM/DD HH:mm:ss',
                    )}

                    <hr style={stylehrright} />
                  </Label>
                  <Label xs={6} style={style}>
                    <p>送信元ユーザー</p>
                    <hr style={stylehrleft} />
                  </Label>
                  <Label xs={6} style={style}>
                    {scheduleMail.sender?.nickName}
                    <hr style={stylehrright} />
                  </Label>
                  <Label xs={6} style={style}>
                    <p>登録環境</p>
                    <hr style={stylehrleft} />
                  </Label>
                  <Label xs={6} style={style}>
                    {displayTextScheduleSendTypeOptions(scheduleMail.type)}
                    <hr style={stylehrright} />
                  </Label>
                  <Label xs={6} style={style}>
                    <p>配信中ステイタス</p>
                    <hr style={stylehrleft} />
                  </Label>
                  <Label xs={6} style={style}>
                    {displayTextScheduleCronjobTypeOptions(
                      scheduleMail.cronjobStatus,
                    )}
                    <hr style={stylehrright} />
                  </Label>
                </Row>
                <Row className="mt-2">
                  <Col xs={12}>
                    <FormLabel component="legend">配信設定</FormLabel>
                    <RadioGroup
                      defaultValue={scheduleMail.sendStatus}
                      name="sendStatus"
                      options={scheduleSendStatusOptions}
                      onChange={handleChange}
                      // classCustom={styles.customRadioGroup}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={12}>
                    <FormLabel component="legend">
                      送信元メールアドレス
                    </FormLabel>
                  </Col>
                  <Col xs={12}>
                    <select
                      onChange={handleFromMailAddress}
                      style={{ width: '100%' }}
                    >
                      {fromMails.map((t) => (
                        <option
                          selected={scheduleMail.fromAddress === t.key}
                          data-key={t.key}
                          value={t.value}
                        >
                          {t.value}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <SearchPresetSelect
                      isShow={true}
                      isHideLoadBtn
                      selectedPresets={selectedPresets}
                      handleChange={handleChangeSelectPresets}
                      titleStyle={{
                        color: 'rgba(0, 0, 0, 0.54)',
                        fontWeight: '400',
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Button
            variant="contained"
            className="btn-custom btn-red float-right"
            onClick={handleRemove}
            style={{ marginBottom: 2 }}
          >
            削除
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}
