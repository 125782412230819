import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {
  CircularProgress,
  createMuiTheme,
  FormLabel,
  InputLabel,
  MuiThemeProvider,
  TextField,
} from '@material-ui/core';
import { Card, Col, FormGroup, Row } from 'reactstrap';
import RadioGroup from '../../Components/RadioGroup';
import SelectList from '../../Components/SelectList';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { createCampaign } from '../../Api/campaignApi';
import { toast } from 'react-toastify';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import './style.css';
import configs from '../../Config/config';
import { uploadImage } from '../../Api/uploadApi';
import { deleteImageStorage } from '../../Api/imageApi';
import Util from '../../Util/Util';
import {
  TEXT_HTML_MANAGEMENT_CONTENTS_CAMPAIGN_ADD_LINK_MODAL,
  TEXT_HTML_MANAGEMENT_UPLOAD_FILE_TO_WEBVIEW_LINK_MODAL,
} from '../../Common/modal_text_info';
import {
  fileDataType,
  hiddenStatusRequired,
  inspectionStatusRequired,
  sexOptionsRequired,
  typeConnectionsRequired,
} from '../../Util/utils';
import {
  APP_ALLOW_UPLOAD_TYPE,
  DEVICE_TYPE_IN_LOG,
  DIR_FOLDER_IMAGE,
  HIDDEN_TYPE,
  INSPECTION_TYPE,
} from '../../Common/constant';
import FileUploadModal from '../../Components/FileUploadModal';
import { AppDestinationOptions, placeOptions, TOAST_MESSAGE } from '../../Common/constant_text';
import { DeleteImageButton } from '../../Components/IconImageAction';

registerLocale('ja', ja);

const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      h6: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      },
    },
  },
});

function AddCampaignModal({ onClose, onUpdate }) {
  const [submitting, setSubmitting] = useState(false);
  const [hourStart, setHourStart] = useState('00');
  const [minusStart, setMinusStart] = useState('00');
  const [hourEnd, setHourEnd] = useState('00');
  const [minusEnd, setMinusEnd] = useState('00');
  const [dateStart, setDateStart] = useState(moment(new Date(moment().startOf('day')).getTime()).format('YYYY-MM-DD'));
  const [dateEnd, setDateEnd] = useState(moment(new Date(moment().add(1, 'days').startOf('day')).getTime()).format('YYYY-MM-DD'));
  const [campaign, setCampaign] = useState({
    gender: 0,
    confirmAge: INSPECTION_TYPE.NO_INSPECTION, // 0
    place: 1,
    typeDevice: DEVICE_TYPE_IN_LOG.WEB, // 0
    displaySetting: HIDDEN_TYPE.NO_HIDDEN, // 0
    rank: 0,
    start_time: new Date(moment().startOf('day')).getTime(),
    end_time: new Date(moment().add(1, 'days').startOf('day')).getTime(),
    app_destination: 0,
  });

  const [showDialogDragDrop, setShowDialogDragDrop] = useState(false);
  const [fileData, setFileData] = useState(fileDataType);

  useEffect(() => {
    if (fileData && fileData.file) {
      uploadStartLogic(fileData.file); // Trigger call data
    }
  }, [fileData]);

  const onEmitClose = () => {
    onDeleteImage();
    onClose();
  };

  const onChange = (e) => {
    campaign[e.target.name] = e.target.value;
    setCampaign({ ...campaign, [e.target.name]: e.target.value });
  };

  const handleDate = (date, name) => {
    date = moment(date).format('YYYY-MM-DD');
    if (name === 'start_time') {
      setDateStart(date);
      let startTime = new Date(`${date} ${hourStart}:${minusStart}:00`);
      campaign.start_time = startTime.getTime();
    } else {
      setDateEnd(date);
      let endTime = new Date(`${date} ${hourEnd}:${minusEnd}:00`);
      campaign.end_time = endTime.getTime();
    }
    setCampaign({ ...campaign });
  };

  const handleMinus = (e, name) => {
    let value = e.target.value;
    if (name === 'start_minus') {
      setMinusStart(value);
      let startTime = new Date(`${dateStart} ${hourStart}:${value}:00`);
      campaign.start_time = startTime.getTime();
    } else {
      setMinusEnd(value);
      let endTime = new Date(`${dateEnd} ${hourEnd}:${value}:00`);
      campaign.end_time = endTime.getTime();
    }
    setCampaign({ ...campaign });
  };

  const handleHour = (e, name) => {
    let value = e.target.value;
    if (name === 'start_hour') {
      setHourStart(value);
      let startTime = new Date(`${dateStart} ${value}:${minusStart}:00`);
      campaign.start_time = startTime.getTime();
    } else {
      setHourEnd(value);
      let endTime = new Date(`${dateEnd} ${value}:${minusEnd}:00`);
      campaign.end_time = endTime.getTime();
    }
    setCampaign({ ...campaign });
  };

  const handleSubmit = async () => {
    let isError = false;

    if (!campaign.name) {
      toast.error('設定名を入力してください');
      isError = true;
    }
    if (!campaign.start_time) {
      toast.error('開始日を入力してください');
      isError = true;
    }
    if (!campaign.end_time) {
      toast.error('終了日を入力してください');
      isError = true;
    }
    if (!campaign.app_destination) {
      toast.error('リンク先ページを選択してください');
      isError = true;
    }
    if (!campaign.url) {
      toast.error('バナー画像を登録してください');
      isError = true;
    }
    if (campaign.start_time > campaign.end_time) {
      toast.error('開始日は終了日より前で設定してください');
      isError = true;
    }

    if (isError) {
      return;
    }

    createCampaign(campaign).then((response) => {
      const { data } = response;
      if (data.success) {
        onClose();
        onUpdate();
        setSubmitting(true);
        toast.success(TOAST_MESSAGE.SUCCESS_ADD);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_ADD);
      }
    });
  };

  const onDeleteImage = () => {
    if (campaign.url) {
      deleteImageStorage(Util.getFilePath(campaign.url)).then();
    }
  };

  const uploadStartLogic = async (file, task) => {
    if (configs.allowUpload === APP_ALLOW_UPLOAD_TYPE.SERVER) {
      if (task) {
        task.cancel();
      }

      const filename = new Date().getTime() + '.jpg';
      const formData = new FormData();
      formData.append('imageFile', new File([file], filename));
      const dir = `${DIR_FOLDER_IMAGE.CAMPAIGN}/${moment().format('YYYYMMDD')}`;
      const imagePath = `${configs.apiDomain}/${dir}/${filename}`;
      const { data: uploaded } = await uploadImage(formData, { dir: btoa(dir) });
      if (uploaded.data) {
        onDeleteImage();
        campaign.url = imagePath;
        setCampaign({ ...campaign });
      }
    }
  };

  const handleDeleteImage = (image) => {
    onDeleteImage(image);
    campaign.url = null;
    setCampaign({ ...campaign });
  };

  const handleUploading = (snapshot) => {
    // Todo
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.info('Upload is ' + progress + '% done');
  };

  // const handleUploadError = (payload) => {
  //   console.error('Firebase.storage::FAIL ', payload);
  // };

  const handleUploadSuccess = (url) => {
    if (String(url)) {
      campaign.url = url;
      setCampaign({ ...campaign });
    }
  };

  let hour = [];
  for (let index = 0; index < 24; index++) {
    if (index < 10) {
      hour.push('0' + index);
    } else {
      hour.push(index);
    }
  }

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={true}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <MuiThemeProvider theme={theme}>
          <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
            <p className={'d-title'}>キャンペーン設定－新規登録</p>
            <div>
              <Button
                onClick={onEmitClose}
                color="primary"
                disabled={submitting}
                className="btn-custom btn-red"
              >
                閉じる
              </Button>
              <Button
                onClick={() => handleSubmit()}
                color="primary"
                disabled={submitting}
                className="btn-custom btn-blue mr-3"
              >
                {submitting && <CircularProgress />}
                登録
              </Button>
            </div>
          </DialogTitle>
          <div className={'d-title-info-box'}>
            <div className={'text mb-0'}
                 dangerouslySetInnerHTML={{ __html: TEXT_HTML_MANAGEMENT_CONTENTS_CAMPAIGN_ADD_LINK_MODAL }}></div>
          </div>
        </MuiThemeProvider>
        <DialogContent>
          <Row className="pb-3">
            <Col xs={6}>
              <Card body>
                <FormGroup row>
                  <Col xs={12}>
                    <InputLabel shrink>
                      <span className="text-red">*</span>設定名
                    </InputLabel>
                    <TextField
                      type="text"
                      defaultValue={campaign.name}
                      name="name"
                      onChange={onChange}
                      className="w-100"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs={12}>
                    <InputLabel shrink>表示場所</InputLabel>
                    <SelectList
                      name="place"
                      defaultValue={campaign.place}
                      options={placeOptions}
                      onChange={onChange}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs={12}>
                    <InputLabel shrink>表示端末</InputLabel>
                    <RadioGroup
                      name="typeDevice"
                      defaultValue={campaign.typeDevice}
                      options={typeConnectionsRequired()}
                      onChange={onChange}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs={12}>
                    <FormLabel component="legend">×××性別</FormLabel>
                    <RadioGroup
                      name="gender"
                      defaultValue={campaign.gender}
                      options={sexOptionsRequired()}
                      onChange={onChange}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs={12}>
                    <FormLabel component="legend">×××年齢認証</FormLabel>
                    <RadioGroup
                      name="confirmAge"
                      defaultValue={campaign.confirmAge}
                      options={inspectionStatusRequired()}
                      onChange={onChange}
                    />
                  </Col>
                </FormGroup>
              </Card>
            </Col>
            <Col xs={6}>
              <Card body className="mb-2">
                <FormGroup row>
                  <Col>
                    <FormLabel component="legend">開始日時</FormLabel>
                  </Col>
                  <Col className="datePicker" xs={12}>
                    <DatePicker
                      selected={new Date(campaign.start_time)}
                      className="date"
                      onChange={(e) => handleDate(e, 'start_time')}
                      locale="ja"
                      dateFormat="yyyy/MM/dd"
                    />
                    <select
                      defaultChecked={hourStart}
                      onChange={(e) => handleHour(e, 'start_hour')}
                      className="hour"
                    >
                      {hour.map((value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                    <select
                      onChange={(e) => handleMinus(e, 'start_minus')}
                      className="minus"
                    >
                      <option value="00">00</option>
                      <option value="30">30</option>
                    </select>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col>
                    <FormLabel component="legend">終了日時</FormLabel>
                  </Col>
                  <Col className="datePicker" xs={12}>
                    <DatePicker
                      selected={new Date(campaign.end_time)}
                      className="date"
                      onChange={(e) => handleDate(e, 'end_time')}
                      locale="ja"
                      dateFormat="yyyy/MM/dd"
                    />
                    <select
                      defaultChecked={hourEnd}
                      onChange={(e) => handleHour(e, 'end_hour')}
                      className="hour"
                    >
                      {hour.map((value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                    <select
                      onChange={(e) => handleMinus(e, 'minus_end')}
                      className="minus"
                    >
                      <option value="00">00</option>
                      <option value="30">30</option>
                    </select>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col xs={12}>
                    <FormLabel component="legend">表示設定</FormLabel>
                    <RadioGroup
                      name="displaySetting"
                      defaultValue={campaign.displaySetting}
                      options={hiddenStatusRequired()}
                      onChange={onChange}
                    />
                  </Col>
                </FormGroup>
              </Card>
              <Card body>
                <FormGroup row>
                  <Col xs={12}>
                    <InputLabel shrink>
                      <span className="text-red">*</span>リンク先
                    </InputLabel>
                    <SelectList
                      name="app_destination"
                      defaultValue={campaign.app_destination}
                      options={AppDestinationOptions}
                      onChange={onChange}
                      label="リンク先"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row className="pt-2 mb-0">
                  <Col xs={12}>
                    <FormLabel component="legend">
                      <span className="text-red">*</span>リンク画像を選択
                    </FormLabel>
                  </Col>
                  <Col xs={12}>
                    {!campaign.url && (
                      <label>
                        <AddBoxIcon onClick={() => setShowDialogDragDrop(true)}
                                    color="action"
                                    style={{ width: 50, height: 50, objectFit: 'cover', margin: 'auto' }}
                        />
                      </label>
                    )}
                  </Col>
                </FormGroup>
              </Card>
            </Col>
          </Row>
          {campaign.url && (
            <Card body className="my-3 align-items-center">
              <label
                style={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  width: 220,
                }}
              >
                <img
                  style={{
                    width: '100%',
                    objectFit: 'contain',
                  }}
                  src={campaign.url}
                  alt="addCampaign"
                />
                {campaign.url && (
                  <DeleteImageButton onClick={() => handleDeleteImage(campaign.url)} />
                )}
              </label>
            </Card>
          )}
        </DialogContent>
      </Dialog>

      {showDialogDragDrop && (
        <FileUploadModal showDialogDragDrop={showDialogDragDrop}
                         setShowDialogDragDrop={setShowDialogDragDrop}
                         setFileData={setFileData}
                         fileData={fileData}
                         dataInfoHeadText={TEXT_HTML_MANAGEMENT_UPLOAD_FILE_TO_WEBVIEW_LINK_MODAL}
        />
      )}
    </>
  );
}

export default AddCampaignModal;
