import { Card, MuiThemeProvider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import SortArrows from '../../../Components/SortArrows';
import { tableNewTheme } from '../../../ConfigUI/tableTheme';
import NoPhoto from '../../../assets/image/no_user_photo.png';
import Util from '../../../Util/Util';
import { changeAllLinksForContent } from '../../../Util/utils';
import { EMAIL_SEND_CATEGORIES } from '../../../Common/constant';

function LogSendList(props) {
  const { labelDb } = props;
  const {
    logSendSms = [], pageIndex, pageSize, loading, handleClickDetail,
    filter = { sort: {} },
    onSort = (sortByKey) => {
    },
  } = props;

  const displayHtmlContent = (content) => {
    const brRegex = new RegExp(`<br />`, 'gi');
    return (content || '').replace(/<p>/ig, '<span>').replace(/<\/p>/ig, '</span>').replace(brRegex, '');
  };

  useEffect(() => {
    changeAllLinksForContent('content-email-log');
  }, []);

  return (
    <div className="align-top">
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号"
                    name="phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="性別"
                    name="gender"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="タイトル"
                    name="title"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="本文"
                    name="content"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>送信元メールアドレス</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="日時"
                    name="createdDate"
                    onClick={onSort}
                    defaultName="createdDate"
                  />
                </TableCell>
                <TableCell>ボタン</TableCell>
              </TableRow>

              {logSendSms.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell>
                    {(pageIndex - 1) * pageSize + index + 1}
                  </TableCell>
                  <TableCell>
                    {item?.category === EMAIL_SEND_CATEGORIES.MEMBER && (
                      <span>{item?.user?.phoneNumber}</span>
                    )}
                    {/*AGENT*/}
                    {item?.category === EMAIL_SEND_CATEGORIES.AGENT && (
                      <span>{item?.agent?.phoneNumber}</span>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {item?.category === EMAIL_SEND_CATEGORIES.MEMBER && (<img
                      className="imgRound"
                      src={item.user?.avatar ? item.user.avatar.url : NoPhoto}
                      alt=""
                    />)}
                    {/*AGENT*/}
                    {item?.category === EMAIL_SEND_CATEGORIES.AGENT && (<img
                      className="imgRound"
                      src={item.agent?.avatar ? item.agent.avatar.url : NoPhoto}
                      alt=""
                    />)}
                  </TableCell>
                  <TableCell>
                    {item?.category === EMAIL_SEND_CATEGORIES.MEMBER && (
                      <div>{item?.user?.nickName}</div>
                    )}
                    {item?.category === EMAIL_SEND_CATEGORIES.AGENT && (
                      <div>{item?.agent?.nickName}</div>
                    )}
                  </TableCell>
                  <TableCell>
                    {item?.category === EMAIL_SEND_CATEGORIES.MEMBER && Util.genderIdToText(item.user?.gender)}
                    {item?.category === EMAIL_SEND_CATEGORIES.AGENT && Util.genderIdToText(item.agent?.gender)}
                  </TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>
                    <div className="content-email-log"
                         dangerouslySetInnerHTML={{ __html: displayHtmlContent(item.content) }}></div>
                  </TableCell>
                  <TableCell>{item.mailAddress}</TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(item.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                    </time>
                  </TableCell>
                  <TableCell className="d-color-link" style={{ cursor: 'pointer' }}>
                    <Link onClick={() => handleClickDetail(item)}>表示</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
    </div>
  );
}

export default LogSendList;
