import { Avatar, Button, List, ListItem, ListItemAvatar, ListItemText, MuiThemeProvider } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import NoPhoto from '../../assets/image/no_user_photo.png';
import NoAvatar from '../../assets/image/sys_photo.png';
import UserDelete from '../../assets/image/delete_user.png';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import moment from 'moment';
import _ from 'lodash';
import { Col, Row } from 'reactstrap';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import AppChat from './ChatSupportManagement/AppChat';
import Util from '../../Util/Util';
import socket from '../../socket';
import { getAllChatRoom } from '../../Api/chatSupportAPI';
import ImageModal from '../../Components/ImageModal';
import { IS_ADMIN, MEMBER_USER_STATUS } from '../../Common/constant';

const recordStyle = { display: 'grid', gridTemplateColumns: '31% 31% 8% 20% 10%' };

export default function OpeWorkPageIndividual() {
  const inputEl = useRef(null);
  const [filter, setFilter] = useState({ pageIndex: 1, pageSize: 1000 });
  const [listConversations, setListConversations] = useState();
  const [listMessages, setListMessages] = useState();
  const [conversationId, setConversationId] = useState();
  const [values, setValues] = useState({});
  const [selectedIndex, setSelectedIndex] = useState();
  const [loadMore, setLoadMore] = useState(true);
  const [imageUrl, setImageUrl] = useState();
  const [isOpeChara, setIsOpeChara] = useState(false);

  // const dispatch = useDispatch();
  const conversations = useSelector((state) => state.listConversation.items);
  const [supportChatRooms, setSupportChatRooms] = useState([]);

  const getAllChatSupport = async () => {
    try {
      const result = await getAllChatRoom(filter);

      if (result.status === 200) {
        setSupportChatRooms(result.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAllChatSupport();
  }, []);

  useEffect(() => {
    if (conversations) {
      const workPage = conversations?.map((message, index) => {
        if (message.sender.charaStatus) {
          const source = message.sender;
          const target = message.receiver;
          message.sender = target;
          message.receiver = source;
        }

        return message;
      });
      workPage.sort(
        (a, b) =>
          b.lastMessage - a.lastMessage ||
          b.messages[0]?.createdDate - a.messages[0]?.createdDate,
      );
      setListConversations(workPage);
    }
  }, [conversations]);

  const handleScroll = (e) => {
    const bottom =
      Number((e.target.scrollHeight - e.target.scrollTop).toFixed(0)) - e.target.clientHeight < 50;
    if (bottom && !loadMore) {
      setFilter({ ...filter, pageSize: filter.pageSize + 1 });
      setLoadMore(bottom);
    }
  };

  const handleListItemClick = (
    sender,
    receiver,
    messages,
    index,
    conversationId,
  ) => {
    setSelectedIndex(index);
    setValues((values) => ({
      ...values,
      index,
      sender,
      receiver,
    }));
    let messagesBaseDate = _.groupBy(messages, function(message) {
      return moment(Number(message.createdDate)).format('YYYY-MM-DD');
    });

    setListMessages(messagesBaseDate);
    setConversationId(conversationId);

    socket.emit('joinSupportConversation', { conversationId });
  };

  const scrollToRef = (ref) => {
    if (ref.current) ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (listMessages) {
      scrollToRef(inputEl);
    }
  }, [listMessages]);

  const showImage = (imageUrl) => {
    setImageUrl(imageUrl);
  };

  return (
    <MuiThemeProvider theme={tableNewTheme}>
      <Row>
        <Col xs={6} className="mt-3">
          <Row>
            <Col xs={12}>
              <div className="container-conversation">
                <div className="list-conversation" onScroll={handleScroll}>
                  <List component="nav" className="item-head">
                    <ListItem style={recordStyle}>
                      <div style={{ paddingLeft: 50 }}>ユーザー</div>
                      <div style={{ paddingLeft: 72 }}>サポート</div>
                      <div style={{ textAlign: 'start' }}></div>
                      <div style={{ marginLeft: 10, textAlign: 'start' }}>最終チャット</div>
                    </ListItem>
                  </List>
                  {supportChatRooms &&
                  supportChatRooms?.map((item, index) => (
                    <List component="nav" className="item-content" key={index}>
                      <ListItem
                        style={recordStyle}
                      >
                        <div className="user" style={{ width: '100%' }}>
                          <Link to={`/${isOpeChara ? 'character' : 'user'}/${item?.sender?.id}/detailshort`}
                                target="_blank" rel="noopener noreferrer"
                                onClick={() => localStorage.setItem('userId', item?.sender?.id)}
                          >
                            <img alt="Avatar"
                                 src={
                                  item?.sender?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                                  item?.sender?.avatar?.url || NoPhoto}
                                 className="avatar"
                            />
                          </Link>
                          <ListItemText primary={item?.sender?.nickName} />
                        </div>

                        <div className="user d-flex d-justify-start" style={{ width: '100%' }}>
                          <SyncAltIcon className="icon-transfer" />
                            <img
                              alt="Avatar"
                              src={item?.receiver?.avatar?.url || NoAvatar}
                              className="avatar"
                            />
                          <div className="user" style={{ flex: '1 1 auto' }}><span className='user-other'>サポート事務局</span>
                          </div>
                        </div>
                        <div style={{ textAlign: 'start' }}></div>
                        <div style={{ marginLeft: 10, textAlign: 'start' }}>
                          {Util.timestampToDate(item.messages[0]?.createdDate, 'YYYY/MM/DD')}
                          <br />
                          {Util.timestampToDate(item.messages[0]?.createdDate, 'HH:mm')}
                        </div>

                        <div style={{ textAlign: 'end', position: 'relative' }}>
                          <Button
                            style={{
                              margin: 'auto',
                              width: 'auto',
                              backgroundColor: selectedIndex === index ? 'rgb(0, 90, 193)' : '#58C2FE',
                              color: 'white',
                              maxWidth: 24,
                              minWidth: 24,
                            }}
                            variant="contained"
                            onClick={() =>
                              handleListItemClick(
                                item?.sender,
                                item?.receiver,
                                item?.messages,
                                index,
                                item.id,
                              )
                            }>
                            <ArrowForwardIosIcon className="icon-transfer" />
                          </Button>
                          {(!item.messages[0].replied && item.messages[0].senderId !== IS_ADMIN.ADMIN) && 
                            <div
                              style={{ width: 10,
                              height: 10,
                              borderRadius: '50%' ,
                              backgroundColor: 'red',
                              position: 'absolute',
                              top: 2,
                              right: -3 }} >
                            </div>}
                        </div>
                      </ListItem>
                    </List>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Col>

        <Col xs={6} className={['mt-3', conversationId ? 'd-wrapper-conversation' : '']}>
          <div className={[(conversationId ? 'container-conversation' : '')]}>
            <div className="conversation-content">
              {values.sender && (
                <React.Fragment>
                  <div className="d-head-chat timebar">
                    <div className="flexCenter">
                      <ListItemAvatar>
                        <Avatar alt="Avatar" src={
                          values?.sender?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                          values.sender.avatar?.url ?? NoPhoto} />
                      </ListItemAvatar>
                      <ListItemText className="d-title" primary={values.sender.nickName} />
                      <SyncAltIcon style={{ margin: '0 10px' }} />
                      <ListItemAvatar>
                        <Avatar
                          alt="Avatar"
                          src={values.receiver.avatar?.url ?? NoAvatar}
                        />
                      </ListItemAvatar>
                      <ListItemText className="d-title" primary={'サポート'} />
                    </div>
                  </div>
                  {conversationId && (
                    <AppChat
                      conversationId={conversationId}
                      values={values}
                      viewImage={showImage}
                      listMessages={listMessages}
                      setListMessages={setListMessages}
                    />
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </Col>
      </Row>

      {imageUrl && (
        <ImageModal
          image={{ url: imageUrl }}
          onClose={() => setImageUrl(null)}
        />)}
    </MuiThemeProvider>
  );
}
