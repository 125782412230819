import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from '../../../Components/LoadingBar';
import Pagination from '../../../Components/Pagination';
import ScheduleSmsList from './ScheduleSmsList';
import TimerSmsSearchBox from './TimerSmsSearchBox';
import {
  estimateScheduleSmsReceivers,
  loadScheduleSms,
} from '../../../Redux/Actions/scheduleSms';
import ScheduleSmsDetailModal from './ScheduleSmsDetailModal';

const pageSize = 50;

function SendingTimerSms() {
  const {
    loading,
    loadingReceivers,
    items: scheduleSms,
    totalRow,
  } = useSelector((state) => state.scheduleSms);
  const [filter, setFilter] = useState({
    timer: 0,
    sort: { sendTime: 'DESC' },
  });
  const [pageIndex, setPageIndex] = useState(1);
  const [timerSmsDetail, setTimerSmsDetail] = useState(null);

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (filter.sort[sortByKey] === undefined) {
      clientRules[sortByKey] = 'DESC';
    }
    if (filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadScheduleSms({ pageIndex, pageSize, ...filter }));
  }, [dispatch, pageIndex, filter]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadScheduleSms({ ...filter, pageIndex, pageSize }));
  };

  const handleClose = () => {
    setTimerSmsDetail(null);
  };

  const reload = () => {
    dispatch(loadScheduleSms({ ...filter, pageIndex, pageSize }));
  };

  const getEstimateReceivers = () => {
    dispatch(
      estimateScheduleSmsReceivers({
        ...filter,
        pageIndex,
        pageSize,
        estimateReceivers: true,
      }),
    );
  };

  const handleClickDetail = (item) => {
    setTimerSmsDetail(item);
  };

  const handleSearch = (searchFilter) => {
    setFilter({ ...searchFilter, sort: filter.sort });
    setPageIndex(1);
  };

  const onClear = () => setFilter({ timer: 0, sort: { sendTime: 'DESC' } });

  return (
    <div>
      <LoadingBar show={loading} />
      <TimerSmsSearchBox
        loadingReceivers={loadingReceivers}
        onSubmit={handleSearch}
        onClear={onClear}
        estimateReceivers={getEstimateReceivers}
      />
      {timerSmsDetail && (
        <ScheduleSmsDetailModal
          onClose={handleClose}
          scheduleSms={timerSmsDetail}
          reload={reload}
        />
      )}
      <ScheduleSmsList
        loading={loading}
        loadingReceivers={loadingReceivers}
        logSendSms={scheduleSms}
        pageIndex={pageIndex}
        pageSize={pageSize}
        filter={filter}
        onSort={onSort}
        handleClickDetail={handleClickDetail}
      />
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default SendingTimerSms;
